import * as localForage from "localforage";

import UserData, { StorageType } from '@/components/leasing/Showroom/src/components/providers/UserData';
import VueRouter, { RouteConfig } from 'vue-router';

import About from '../views/about/about';
import CarCalc from '@/components/leasing/Showroom/src/components/carcalc';
// import CarDetail from '@/components/leasing/Showroom/src/components/cardetail';
import CarList from '@/components/leasing/Showroom/src/components/carlist';
import Confirmation from '../views/confirmation/confirmation';
import Confirmation_finished from '../views/confirmation/confirmation_finished';
import Contact from '../views/contact/contact';
import CustomRouteHandler from '../views/CustomRouteHandler/CustomRouteHandler';
// import ErhvervCars from '../views/erhverv_cars/erhverv_cars';
import ErhvervFAQ from '../views/erhverv_faq/erhverv_faq';
import Jobs from '../views/jobs/jobs';
import JobsSingle from '../views/jobs/jobs_single';
// App views
import LandingPage from '../views/landingpage/landingPage';
// LeasingPro
import LeasingPro from '@/components/leasingpro/App.vue';
// import LikedCars from '../views/liked_cars/liked_cars';
import Login from '@/components/leasing/Showroom/src//components/login/Login.vue';
import Logins from '../views/logins/logins';
import Newbanking from "@/components/newbanking/newbanking";
import News from '../views/news/news';
// import PrivatCars from '../views/privat_cars/privat_cars';
// import PrivatFAQ from '../views/privat_faq/privat_faq';
// import PrivatLeasing from '../views/privat_leasing/privat_leasing';
// import PrivatLeasingShowroom from '@/components/leasing/privat/leasing';
// import PrivatReleasingShowroom from '@/components/leasing/privat/releasing';
import ProPlus from '@/components/leasing/proplus/proplus';
import ReferalLanding from '../views/referal_landing/ReferalLanding';
// import Upload from '@/components/leasing/Showroom/src/components/upload';
import Vue from 'vue';
import eventBus from '../eventBus';
import PrivatLeasingShowroom from '@/components/leasing/privat/leasing';
// ShowRoom
import store from '@/components/leasing/Showroom/src/store';
import BecomeCustomer from '@/views/become_customer/BecomeCustomer.vue';

const ifAuthenticated = (to, from, next:any) => {
  let loginRequired = store.getters.getUserData.loginRequired;
  if (loginRequired !== undefined && loginRequired) {
    localForage.getItem(StorageType.TOKEN).then((value) => {
      if (value !== null) {
        eventBus.$emit("forhandler-loggedin");
        next();
        return;
      } else {

        eventBus.$emit("forhandler-loggedout");
        next('/log-paa');
      }
    }).catch((err) => {
      eventBus.$emit("forhandler-loggedout");
      next('/log-paa');
    });
  } else {
    next();
    return;
  }
}

const logout = (to, from, next:any) => {
  eventBus.$emit("forhandler-loggedout");
  UserData.logout().then(() => {
    next();
    return;
  });
}

const logoutForhandler = (to, from, next:any) => {
  eventBus.$emit("forhandler-loggedout");
  UserData.logout().then(() => {
    router.push({ name: 'logins'});
    return;
  });
}

Vue.use(VueRouter);

// const showroomRoutingPrivat = [
//   {
//     path: '/',
//     name: 'CarListPrivat',
//     component: CarList,
//   },
//   {
//     path: 'upload/:token',
//     name: 'UploadPrivat',
//     component: Upload
//   },
//   {
//     path: ':vehicleId',
//     name: 'CarCalcPrivat',
//     component: CarCalc,
//   }
// ]
// const showroomRoutingErhverv = [
//   {
//     path: '/',
//     name: 'CarListErhverv',
//     component: CarList,
//   },
//   {
//     path: 'upload/:token',
//     name: 'UploadErhverv',
//     component: Upload
//   },
//   {
//     path: ':vehicleId',
//     name: 'CarCalcErhverv',
//     component: CarCalc,
//   }
// ]

const routes: RouteConfig[] = [

  // Wrapper start
  // {
  //   path: '/',
  //   name: 'home',
  //   component: LandingPage,
  // },
  {
    path: '/',
    name: 'home',
    component: CustomRouteHandler,
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/bliv-kunde',
    name: 'bliv-kunde',
    component: BecomeCustomer,
  },
  {
    path: '/om',
    name: 'about',
    component: About,
  },
  {
    path: '/log-paa',
    name: 'logins',
    component: Logins
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: Confirmation,
    props: true
  },
  {
    path: '/confirmation_finished',
    name: 'confirmation_finished',
    component: Confirmation_finished,
    props: true
  },

  // 404
  {
    path: '*',
    name: 'CustomRouteHandler',
    component: CustomRouteHandler,
  },

  //News
  {
    path: '/nyheder',
    name: 'News',
    component: News,
  },
  {
    path: '/nyheder/:article',
    name: 'News Single',
    // component: NewsSingle,
    component: CustomRouteHandler,
  },


  //Referal landing
  {
    path: '/secret',
    name: 'ReferalLanding',
    component: ReferalLanding,
    meta: {
      noindex: true
    }
  },

  //Jobs
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
  },
  {
    path: '/jobs/:job',
    name: 'Jobs Single',
    component: JobsSingle,
  },

  // Leasing
  // {
  //   path: '/privat',
  //   name: 'PrivatLeasing',
  //   component: PrivatLeasing,
  // },
  // {
  //   path: '/privat/faq',
  //   name: 'PrivatFAQ',
  //   component: PrivatFAQ,
  // },
  // {
  //   path: '/privat/showroom',
  //   name: 'PrivatCars',
  //   component: PrivatCars,
  //   children: showroomRoutingPrivat
  // },
  // {
  //   path: '/privat/liked',
  //   name: 'LikedCarsPrivat',
  //   component: LikedCars,
  // },
  {
    path: '/auth/logout',
    name: 'AuthLogout',
    redirect: (to) => {
      logout(to, undefined, () => {});
      return { name: 'home' }
    }
  },
  {
    path: '/privat/leasing/forhandler',
    component: PrivatLeasingShowroom,
    children:  [
      {
        path: '/',
        name: 'DealerCarList',
        component: CarList,
        beforeEnter: ifAuthenticated
      },
      {
        path: '/login',
        name: 'ForhandlerLogin',
        component: Login,
        beforeEnter: logout
      },
      {
        path: '/logout',
        name: 'ForhandlerLogout',
        component: Login,
        beforeEnter: logoutForhandler
      },
      {
        path: ':vehicleId',
        name: 'DealerCarCalc',
        component: CarCalc,
        beforeEnter: ifAuthenticated
      },
    ]
  },
  // {
  //   path: '/privat/releasing',
  //   component: PrivatReleasingShowroom,
  //   children:  [
  //     {
  //       path: '/',
  //       component: CarList,
  //     },
  //     {
  //       path: ':make/:model',
  //       name: 'ReleasingDetail',
  //       component: CarDetail,
  //       props: (route) => ({ query: route.query.used}),
  //     },
  //     {
  //       path: ':make/:model/:vehicleId',
  //       name: 'ReleasingCarCalc',
  //       component: CarCalc,
  //     }
  //   ]
  // },
  {
    path: '/erhverv',
    name: 'ErhvervLeasing',
    // component: ErhvervsLeasing,
    component: CustomRouteHandler,
  },
  {
    path: '/erhverv/faq',
    name: 'ErhvervFAQ',
    component: ErhvervFAQ,
  },
  // {
  //   path: '/erhverv/showroom',
  //   name: 'ErhvervCars',
  //   component: ErhvervCars,
  //   children: showroomRoutingErhverv
  // },
  // {
  //   path: '/erhverv/liked',
  //   name: 'LikedCarsErhverv',
  //   component: LikedCars,
  // },
  // {
  //   path: '/erhverv/showroom',
  //   name: 'ErhvervCars',
  //   component: ErhvervCars,
  //   children: showroomRoutingErhverv
  // },

  // Leasing Pro
  {
    path: '/leasingpro',
    name: 'leasingpro',
    component: LeasingPro,
  },

  // Leasing Pro+
  {
    path: '/leasing/fleet-management/leasing-pro-plus',
    name: 'leasingproplus',
    component: ProPlus,
  },

  // NewBanking
  {
    path: '/newbanking',
    name: 'newbanking',
    component: Newbanking,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {   // Jump to anchor with scroll animation
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
    return { x: 0, y: 0 }
  }
});

export default router;
