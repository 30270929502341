import { Component, Vue } from "vue-property-decorator";

import template from "./DynamicPage.vue";

@Component({
    mixins:[template],
    name: "DynamicPage",
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
    }
})
export default class DynamicPage extends Vue {
}