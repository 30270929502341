<template>
  <div class="main about-page-main">
    
    <section class="section fade-on-enter hero-section">
      <div class="wrap">
        <div class="flex my-flex-row my-flex-nowrap flex-s3">
          <div class="side">
            <h1 class="h1-2 title" >{{heroBlock.title}}</h1>
            <div class="content" v-html="heroBlock.content"></div>
          </div>
          <div v-if="heroBlock.image" class="image">
            <img :src="heroBlock.image.sizes.large" :alt="heroBlock.image.info ? heroBlock.image.info.alt : ''" />
          </div>
        </div>
      </div>
    </section>


    <section class="section fade-on-enter history-section">
      <div class="wrap">
        <h2 class="section-title">{{historyBlock.title}}</h2>
        <div class="history-list">  
          <div class="item " v-for="item in historyBlock.history" :key="item.year" >
            <span class="line">
              <span class="l1"></span>
              <span class="c"></span>
              <span class="l2"></span>
            </span>
            <div class="inner fade-on-enter">

              <p class="year">{{item.year}}</p>
              <div class="description"><p>{{item.description}}</p></div>

              <span class="spike">
                <svg width="49" height="227" viewBox="0 0 49 227" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                  <path d="M1.85913 0C10.8146 0 18.6785 5.95331 21.1087 14.5728L47.4699 108.073C48.4705 111.622 48.4705 115.378 47.4699 118.927L21.1087 212.427C18.6785 221.047 10.8146 227 1.85913 227H0V0H1.85913Z" fill="white"/>
                </svg>
              </span>

            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section fade-on-enter partners-section">
      <div class="wrap">
        <div class="main-flex flex my-flex-row my-flex-nowrap flex-s3">
          <div v-if="partnersBlock.image" class="image">
            <img :src="partnersBlock.image.sizes.large" :alt="partnersBlock.image.info ? partnersBlock.image.info.alt : ''" />
          </div>
          <div class="side">
            <div v-if="partnersBlock.icon" class="icon">
              <img :src="partnersBlock.icon.url" alt="icon" />
            </div>
            <h2 class="title">{{partnersBlock.title}}</h2>
            <div class="content" v-html="partnersBlock.content"></div>
            <div class="logos-list flex my-flex-row my-flex-wrap flex-s2">
              <div class="logo" v-for="logo in patnersLogos" :key="logo.logo.ID" >
                <a target="_blank" v-if="logo.link" :href="logo.link">
                  <img :src="logo.logo.url" :alt="logo.logo.info ? logo.logo.info.alt : ''" />
                </a>
                <span v-else>
                  <img :src="logo.logo.url" :alt="logo.logo.info ? logo.logo.info.alt : ''" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section fade-on-enter media-text-section first-media-text-section">
      <div class="wrap">
        <div class="flex my-flex-row my-flex-nowrap flex-s3">
          <div v-if="firstMediaTextBlock.image" class="image">
            <img :src="firstMediaTextBlock.image.sizes.large" :alt="firstMediaTextBlock.image.info ? firstMediaTextBlock.image.info.alt : ''"/>
          </div>
          <div class="side">
            <div v-if="firstMediaTextBlock.icon" class="icon">
              <img :src="firstMediaTextBlock.icon.url" alt="icon" />
            </div>
            <h2 class="title">{{firstMediaTextBlock.title}}</h2>
            <div class="content" v-html="firstMediaTextBlock.content"></div>
            <div class="button-wrap" v-if="firstMediaTextBlock.button">
              <a v-if="firstMediaTextBlock.button.target == '_blank'" class="button" :href="firstMediaTextBlock.button.url" :target="firstMediaTextBlock.button.target" >{{firstMediaTextBlock.button.title}}</a>
              <router-link v-if="firstMediaTextBlock.button.target != '_blank'" class="button" :to="firstMediaTextBlock.button.url" >{{firstMediaTextBlock.button.title}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section fade-on-enter media-text-section second-media-text-section">
      <div class="wrap">
        <div class="flex my-flex-row my-flex-nowrap flex-s3">
          <div v-if="secondMediaTextBlock.image" class="image">
            <img :src="secondMediaTextBlock.image.sizes.large" :alt="secondMediaTextBlock.image.info ? secondMediaTextBlock.image.info.alt : ''" />
          </div>
          <div class="side">
            <div v-if="secondMediaTextBlock.icon" class="icon">
              <img :src="secondMediaTextBlock.icon.url" alt="icon" />
            </div>
            <h2 class="title">{{secondMediaTextBlock.title}}</h2>
            <div class="content" v-html="secondMediaTextBlock.content"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="section fade-on-enter jobs-section">
      <div class="wrap">
        <div class="main-flex flex my-flex-row flex-s9">
          <div class="left-side">
            <h2 class="section-title">{{jobsBlock.title}}</h2>
            <div class="description" v-html="jobsBlock.description"></div>
          </div>
          <div class="right-side">
            <div class="card-style links-list">
              <h2 class="title">{{jobsBlock.list_title}}</h2>

              
              <div class="buttons">
                <router-link class="item link-button-item" :to="jobsSlug+'/'+job.post_name" v-for="job in jobs" :key="job.ID" >
                  <span class="label">
                    {{job.post_title}}
                  </span><span class="icon">
                    <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </router-link>
              </div>

             
            </div>
          </div>

        </div>
      </div>
    </section>

    <span v-if="cmsPageData && !cmsPageData.loaded"></span>
  </div>
</template>
<script>

import eventBus from '../../eventBus';

export default {
  
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      jobsSlug: "jobs",
      heroBlock: { title: null},
      historyBlock: { title: null },
      partnersBlock: { title: null },
      patnersLogos: null,
      firstMediaTextBlock: {},
      secondMediaTextBlock: {},
      jobsBlock: { title: null },
      jobs: null
    }
  },
  created(){
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
      }
    );
    this.$watch(
      () => this.historyBlock,
      (toParams, previousParams) => {
        this.$nextTick(function(){
          eventBus.$emit("handlePageAnimations");
        });
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        
        this.thisSlug = this.cmsPageData.page.url;
        this.heroBlock = this.cmsPageData.page.about.hero;
        this.historyBlock = this.cmsPageData.page.about.history;
        this.partnersBlock = this.cmsPageData.page.about.partners;
        this.jobsBlock = this.cmsPageData.page.about.jobs;
        this.patnersLogos = this.cmsPageData.page.about.partners_logos;

        this.firstMediaTextBlock = this.cmsPageData.page.about.first;
        this.secondMediaTextBlock = this.cmsPageData.page.about.second;

        this.jobs = this.cmsPageData.page.about.jobs.jobs_list;
        
      }
    );
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.hero-section{
  margin-bottom:80px;
  margin-top:60px;
  .image{
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
    line-height: 0;
    padding-left: 60px;
    box-sizing: border-box;
    img{
      border-radius: 20px;
    }
  }
  .side{
    padding-top: 36px;
    width: 50%;
    padding-left: 0;
    box-sizing: border-box;

    .title{
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
    }
    .content{
      max-width:489px;

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4C6C69;

      ::v-deep p{
        margin-top: 16px;
      }
    }
    .button{
      margin-top: 60px;
    }
  }
}

.history-section{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 80px;
  padding-bottom: 120px;

  .section-title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: #1F4743;
    margin-bottom: 80px;
  }
  .history-list{
    .item{
      display: block;
      text-align: right;
      position: relative;
      padding-bottom: 4px;
      padding-top: 4px;
      
      .line{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        margin-left: -1px;
        left: 50%;
        display: block;
        .l1, .l2{
          display: block;
          background: #69BDB6;
          position: absolute;
          width: 100%;
          height: calc(50% - 16px);
        }
        .l1{
          top: 0;
        }
        .l2{
          bottom: 0;
        }
        .c{
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          margin-top: -12px;
          margin-left: -12px;
          left: 50%;
          top: 50%;
          background: #E1F2F0;
          border: 2px solid #69BDB6;
          box-sizing: border-box;
          border-radius: 100px;
        }
      }
      .inner{
        position: relative;
        display: inline-block;
        background: #FFFFFF;
        border-radius: 0 20px 20px 0;
        width: 50%;
        width: calc(50% - 28px - 48px);
        text-align: left;
        padding-top: 32px;
        padding-left: 40px;
        padding-right: 32px;
        padding-bottom: 48px;
        box-sizing: border-box;
        .spike{
            position: absolute;
            top: 0;
            left: -46px;
            right: auto;
            bottom: 0;
            height: 100%;
            width: 48px;

            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);

            svg{
              width: 100%;
              height: 100%;
            }
          }
      }
      &:nth-of-type(2n + 1){
        text-align: left;
        .inner{
          text-align: right;
          border-radius: 20px 0 0 20px;
          .spike{
            right: -46px;
            left: auto;
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
          }
        }
      }
      .year{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        color: #1F4743;
      }
      .description{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #4C6C69;
      }

      &:first-child{
        .line{
          .l1{
            display: none;
          }
        }
      }
      &:last-child{
        .line{
          .l2{
            display: none;
          }
        }
      }
    }
  }
}


.partners-section{
  margin-top: 80px;
  .image{
    border-radius: 20px;
    img{
      border-radius: 20px;
    }
  }
  .icon{
    margin-bottom: 32px;
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
  }
  .content{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
    ::v-deep p{
      margin-top: 16px;
    }
  }
  .logos-list{
    margin-top: 60px;
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    .logo{
      height: 64px;
      width: 25%;
      text-align: center;
      padding: 0 20px;

      img{
        height: 100%;
        object-fit: contain;
        width: auto;
      }
    }
  }
  .image{
    margin-right: 122px;
    max-width: 387px;
  }
  .side{
    margin-top: 80px;
    max-width: 631px;
  }
}


.media-text-section{
  margin-bottom: 120px;
  .image{
    border-radius: 20px;
    img{
      border-radius: 20px;
    }
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
    margin-bottom: 20px;
  }
  .icon{
    margin-bottom: 32px;
  }
  .content{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
    ::v-deep p{
      margin-top: 16px;
    }
  }
  .button-wrap{
    margin-top: 60px;
  }
}

.first-media-text-section{
  .flex{
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    padding: 80px;
    padding-bottom: 120px;
    box-sizing: border-box;
  }
  .image{
    margin-top: 38px;
    margin-right: 80px;
    max-width: 349px;
  }
  .side{
    max-width: 589px;
  }
}
.second-media-text-section{
  .image{
    margin-right: 122px;
    max-width: 487px;
  }
  .side{
    max-width: 488px;
    margin-top: 80px;
  }
}


.jobs-section{
  background: rgba(105, 189, 182, 0.09);
  border-radius: 40px;

  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;

  margin-top: 160px;

  padding-top: 96px;
  padding-bottom: 146px;

  .left-side{
    width: 41.6666666667%;
    box-sizing: border-box;
    padding-right: 10px;
    padding-top: 48px;
  }
  .right-side{
    width: 58.3333333333%;
    box-sizing: border-box;
    padding-left: 10px;
  }
  .section-title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
    margin-bottom: 20px;
  }
  .description{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
    max-width: 390px;
    ::v-deep p{
      margin-top: 16px;
    }
  }
  .links-list{
    padding: 48px;
    padding-bottom: 33px;

    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 28px;
    }
  }
}

@media (max-width: 1000px) {
  .hero-section{
    .image{
      width: 35%;
      height: 400px;
      img{
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
    .side{
      width: 65%;
    }
  }

  .partners-section{
    .image{
      margin-right: 48px;
    }
  }

  .media-text-section{
    .image{
      margin-right: 48px;
    }
  }

  .jobs-section{
    padding-top: 0;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
    .wrap{
      padding: 0;
    }
    .main-flex{
      display: block;
    }
    .left-side{
      padding-right: 0;
      width: 100%;
      margin-bottom: 48px;
    }
    .right-side{
      padding-left: 0;
      width: 100%;
    }
  }
}
@media (max-width: #{$screen-tablet}) {
  .hero-section{
    .flex{
      -ms-flex-direction: column-reverse;
		  flex-direction: column-reverse;
    }
    .image{
      width: 100%;
      padding: 0;
      height: auto;
      img{
        height: auto;
      }
    }
    .side{
      width: 100%;
    }
  }

  .partners-section{
    .main-flex{
      display: block;
    }
    .image{
      margin-right: 48px;
    }
    .side{
      max-width: none;
      margin-top: 40px;
    }
  }

  .media-text-section{
    .image{
      margin-right: 48px;
    }
  }

  .first-media-text-section{
    .flex{
      display: block;
    }
    .image{
      margin: 0px;
      max-width: none;
    }
    .side{
      max-width: none;
      margin-top: 40px;
    }
  }


  .second-media-text-section{
    .flex{
      display: block;
    }
    .side{
      max-width: none;
      margin-top: 40px;
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .hero-section{
    .side{
      .title{
        margin-bottom: 12px;
        font-size: 32px;
        line-height: 130%;
      }
      .content{
        font-size: 16px;
        line-height: 135%;
      }
    }
  }
  .media-text-section{
    margin-bottom: 80px;
    .title{
      font-size: 32px;
      line-height: 130%;
    }
  }

  .partners-section{
    .title{
      font-size: 32px;
      line-height: 130%;
    }
    .logos-list{
      .logo{
        width: 50%;
      }
    }
  }

  .first-media-text-section{
    .wrap{
      padding-left: 0;
      padding-right: 0;
    }
    .flex{
      padding: 20px;
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }
  .jobs-section{
    padding-top: 0;
    padding-bottom: 48px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 80px;
    .wrap{
      padding: 0;
    }
    .main-flex{
      display: block;
    }
    .left-side{
      padding-right: 0;
      width: 100%;
      margin-bottom: 48px;
    }
    .right-side{
      padding-left: 0;
      width: 100%;
    }
    .section-title{
      font-size: 32px;
      line-height: 130%;
      margin-bottom: 12px;
    }
    .description{
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 48px;
    }
    .links-list{
      padding: 20px;
      .title{
        font-size: 25px;
      }
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  .history-section{
    margin-left: 0;
    margin-right: 0;
    .history-list{
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      .item{
        padding-top: 40px;
        padding-bottom: 40px;
        .line{
          .l1{
            top: 16px;
          }
          .l2{
            bottom: 16px;
          }
          .c{
            top: 0;
          }
        }
        .inner{
          border-radius: 20px;
          text-align: center;
          width: 100%;
          .spike{
            display: none;
            }
        }
        &:nth-of-type(2n + 1){
          .inner{
            border-radius: 20px;
            text-align: center;
            width: 100%;
            .spike{
              display: none;
            }
          }
        }
        &:first-child{
          .line{
            .l1{
              display: block;
            }
          }
        }
        &:last-child{
          .line{
            .l2{
              display: none;
            }
          }
        }
      }
    }
  }
}
@media (max-width: #{$screen-mobile}) {

  .history-section{
    padding-top: 48px;
    padding-bottom: 40px;
    .section-title{
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 34px;
    }
  }
}
</style>