<template>
  <div class="main about-page-main">
    <section v-if="heroBlock" class="section fade-on-enter hero-section">
      <div class="wrap">
        <div class="flex my-flex-row my-flex-nowrap flex-s3">
          <div v-if="heroBlock.image" class="image">
            <img :src="heroBlock.image.sizes.large" :alt="heroBlock.image.info ? heroBlock.image.info.alt : ''"/>
          </div>
          <div class="side">
            <h1 class="h1-1 title">{{ heroBlock.title }}</h1>
            <div class="content" v-html="heroBlock.content"></div>
            <a v-if="heroBlock.show_button" class="button"
               :href="heroBlock.button_link">{{ heroBlock.button_title }}</a>
          </div>
        </div>
      </div>
    </section>

    <section class="section fade-on-enter contact-options-section">
      <div class="wrap">
        <div class="container flex my-flex-row my-flex-nowrap flex-s3">
          <h2 class="h2-1 big-title">{{ optionsBlock?.title }}</h2>
          <div class="options flex my-flex-row my-flex-nowrap flex-s3">
            <div class="option" v-for="(item, index) in optionsBlock?.options" :key="'options-'+index">
              <p class="title">{{ item.title }}</p>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="faqBlock" ref="faqBlock" class="section fade-on-enter faq-list-section">
      <div class="wrap">
        <h2 class="section-title">{{faqBlock.title}}</h2>
        <faq-list :items="faqs" :tags="faqTags"/>
      </div>
    </section>


    <span v-if="cmsPageData && !cmsPageData.loaded"></span>
  </div>
</template>
<script>
import FaqList from "@/components/ui/faq-list/faq-list";

export default {
  components: {FaqList},
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      thisSlug: "",
      jobsSlug: "jobs",
      heroBlock: {title: null},
      optionsBlock: {title: null},
      faqs: null,
      faqTags: null,
      faqBlock: null
    }
  },
  created() {
    this.$watch(
        () => this.cmsPageData,
        (toParams, previousParams) => {
          this.thisSlug = this.cmsPageData.page.url;
          this.heroBlock = this.cmsPageData.page.contacts.hero;
          this.optionsBlock = this.cmsPageData.page.contacts.contact_options;
          this.faqBlock = this.cmsPageData.page.contacts.faq;
          this.faqs = this.cmsPageData.page.contacts.faq_list;
          this.faqTags = this.cmsPageData.page.contacts.tags;


        }
    );
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.hero-section {
  margin-bottom: 80px;
  margin-top: 60px;

  .image {
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
    line-height: 0;
    padding-right: 60px;
    box-sizing: border-box;

    img {
      border-radius: 20px;
    }
  }

  .side {
    padding-top: 36px;
    width: 50%;
    padding-left: 20px;
    box-sizing: border-box;

    .title {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
    }

    .content {
      max-width: 430px;

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4C6C69;
    }

    .button {
      margin-top: 60px;
    }
  }
}

.contact-options-section {
  margin-bottom: 80px;

  .container {
    margin: 0;
    background: rgba(105, 189, 182, 0.09);
    border-radius: 20px;
    padding: 40px;
    padding-bottom: 80px;
  }

  .big-title {
    max-width: 330px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    word-spacing: 9999999px;
  }

  .options {
    width: calc(100% - 330px);

    .option {
      width: 50%;
      padding-right: 70px;
      box-sizing: border-box;
    }

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #1F4743;
      margin-bottom: 16px;
    }

    .content {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      color: #1F4743;
    }

    .content ::v-deep p {
      margin-top: 16px;
    }
  }
}

.faq-list-section {
  background: rgba(105, 189, 182, 0.09);
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-top: 96px;
  padding-bottom: 96px;

  .section-title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #1F4743;
    margin-bottom: 60px;
  }

  .button-wrap {
    margin-top: 80px;
    text-align: center;
  }
}

@media (max-width: 1000px) {
  .hero-section {
    .image {
      width: 35%;
      height: 400px;

      img {
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    .side {
      width: 65%;
    }
  }

  .contact-options-section {
    .container {
      display: block;
      padding-bottom: 60px;
    }

    .big-title {
      max-width: none;
      margin-bottom: 40px;
      width: auto;
    }

    .options {
      width: 100%;

      .option {
        padding-right: 56px;
      }
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  .hero-section {
    .flex {
      flex-direction: column;
    }

    .image {
      width: 100%;
      padding: 0;
      height: auto;

      img {
        height: auto;
      }
    }

    .side {
      width: 100%;
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .hero-section {
    .side {
      .title {
        margin-bottom: 12px;
        font-size: 32px;
        line-height: 130%;
      }

      .content {
        font-size: 16px;
        line-height: 130%;
      }
    }
  }
  .contact-options-section {
    .container {
      display: block;
      padding-bottom: 60px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
    }

    .big-title {
      max-width: none;
      margin-bottom: 0;
      width: auto;

      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
    }

    .options {
      display: block;

      .option {
        padding-right: 0;
        margin-top: 40px;
      }

      .title {
        font-size: 18px;
        line-height: 133%;
      }
    }
  }
}
</style>