<template>
  <div class="calculator-block" :class="openedCalculator ? 'open-mobile' : ''">
    <div class="wrap">
      <div class="block">
          <h2 class="block-title">{{title}}</h2>
          <div class="block-description" v-html="description"></div>

          <div class="calculator">
            <div class="tab-bar">
              <div class="tab" :class="currentTab === 'tax'? 'active': ''" v-on:click="onTaxTabClick">
                <div class="icon"><img :src="require('@/assets/icons/icon-calc-tax.svg')" alt="" /></div>
                <div class="text">
                  <span class="title">{{tax_tab_title}}</span>
                  <span class="description">{{tax_tab_description}}</span>
                </div>
                <a :href="'#'" class="button">Beregn beskatning</a> 
              </div>
              <div class="tab" :class="currentTab === 'car'? 'active': ''" v-on:click="onCarTabClick">
                <div class="icon"><img :src="require('@/assets/icons/icon-calc-car.svg')" alt="" /></div>
                <div class="text">
                  <span class="title">{{car_tab_title}}</span>
                  <span class="description">{{car_tab_description}}</span>
                </div>
                <a :href="'#'" class="button">Beregn firmabil</a> 
              </div>
            </div>

            <div class="calculator-content" v-if="currentTab === 'tax'">
              <div class="mobile-head">
                <div>
                  <span class="logo-wrap">
                    <img :src="require('@/assets/logo.svg')" class="logo" alt="" />
                  </span>
                </div>
                <div>
                  <span class="back-arrow" v-on:click="onCloseCalculatorClick">
                    <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.52344 13L1.52344 7L7.52344 1" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2 7L30 7" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </div>
                <h2 class="block-title">{{title}}</h2>
                <div class="block-description" v-html="description"></div>
              </div>
              <div class="left-side">
                <div>
                  <p class="description">{{ tax_description }}</p>
                  <div class="calculator-inner">
                    <div class="row">
                      <SliderInput :title="tax_tab.f1_title" :info="tax_tab.f1_info" :initValue="formula.L25" :initFrom="tax_tab.f1_from" :step="tax_tab.f1_step" :initTo="tax_tab.f1_to" @changed="inputChanged($event, 'L25')"/>
                    </div>
                    <div class="row">
                      <SliderInput :title="tax_tab.f2_title" :info="tax_tab.f2_info" :initValue="formula.L27" :initFrom="tax_tab.f2_from" :step="tax_tab.f2_step" :initTo="tax_tab.f2_to" @changed="inputChanged($event, 'L27')"/>
                    </div>
                    <div class="row">
                      <PercentInput :title="tax_tab.f3_title" :info="tax_tab.f3_info" :initValue="formula.L26*100" @changed="inputChanged($event, 'L26')"/>
                    </div>
                    <div class="row">
                      <PercentInput :title="tax_tab.f4_title" :info="tax_tab.f4_info" :initValue="formula.L28*100" @changed="inputChanged($event, 'L28')"/>
                    </div>
                    <div class="row">
                      <SliderInput :title="tax_tab.f5_title" :info="tax_tab.f5_info" :initValue="formula.L29" :initFrom="tax_tab.f5_from" :step="tax_tab.f5_step" :initTo="tax_tab.f5_to" @changed="inputChanged($event, 'L29')"/>
                    </div>
                  </div>
                </div>
                <p class="bottom-text" v-html="tax_tab.footer_text"></p>

              </div>
              <div class="right-side">
                <div class="inner">
                  <p class="label">Resultat<InfoButton v-if="tax_tab.result_info" :info="tax_tab.result_info"/></p>
                  <div class="result-boxes" v-if="tax_tab.results">
                    <ResultBox :value="formula.I38" :title="tax_tab.results[0].title" :no-description="'Nettobeløb pr. måned'" :no-info="'a'" />
                    <ResultBox :value="formula.J39" :title="tax_tab.results[1].title" :no-description="'Nettobeløb pr. måned'" :no-info="'a'"  />
                    <ResultBox :value="formula.K38" :title="tax_tab.results[2].title" :no-description="'Nettobeløb pr. måned'" :no-info="'a'"  />
                  </div>
                </div>
              </div>
              <p class="bottom-text"></p>
            </div>

            <div class="calculator-content" v-if="currentTab === 'car'">

              <div class="mobile-head">
                <div>
                  <span class="logo-wrap">
                    <img :src="require('@/assets/logo.svg')" class="logo" alt="" />
                  </span>
                </div>
                <div>
                  <span class="back-arrow" v-on:click="onCloseCalculatorClick">
                    <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.52344 13L1.52344 7L7.52344 1" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2 7L30 7" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </div>
                <h2 class="block-title">{{title}}</h2>
                <div class="block-description" v-html="description"></div>
              </div>

              <div class="left-side">
                <p class="description">{{car_description}}</p>
                
                <div class="calculator-inner">
                    <div class="row">
                      <SliderInput :title="car_tab.f1_title" :info="car_tab.f1_info" :initValue="formula.L47" :initFrom="car_tab.f1_from" :step="car_tab.f1_step" :initTo="car_tab.f1_to" @changed="inputChanged($event, 'L47')"/>
                    </div>
                    <div class="row">
                      <SliderInput :title="car_tab.f2_title" :info="car_tab.f2_info" :initValue="formula.L49" :initFrom="car_tab.f2_from" :step="car_tab.f2_step" :initTo="car_tab.f2_to" @changed="inputChanged($event, 'L49')"/>
                    </div>
                    <div class="row">
                      <PercentInput :title="car_tab.f3_title" :info="car_tab.f3_info" :initValue="formula.L48*100" @changed="inputChanged($event, 'L48')"/>
                    </div>
                    <div class="row">
                      <PercentInput :title="car_tab.f4_title" :info="car_tab.f4_info" :initValue="formula.L50*100" @changed="inputChanged($event, 'L50')"/>
                    </div>
                    <div class="row">
                      <SliderInput :title="car_tab.f5_title" :info="car_tab.f5_info" :initValue="formula.L51" :initFrom="car_tab.f5_from" :step="car_tab.f5_step" :initTo="car_tab.f5_to" @changed="inputChanged($event, 'L51')"/>
                    </div>
                  </div>
                  <p class="bottom-text" v-html="car_tab.footer_text"></p>
              </div>
              <div class="right-side">
                <div class="inner">
                  <p class="label">Resultat</p>
                  <div class="result-boxes">


                    <div class="result-box-2" v-if="car_tab.results">
                      <div>
                        <p class="title">{{car_tab.results[0].title}}</p>
                      </div>
                      <p class="value">{{formatPrice(formula.L30)}} kr.</p>
                      <p class="description-title">{{car_tab.results[0].secondary}}<InfoButton v-if="car_tab.results[0].info" :info="car_tab.results[0].info"/></p>
                      <p class="description">{{car_tab.results[0].description}}</p>
                      
                    </div>
                  </div>
                </div>
                <div class="bottom-section" v-if="car_tab.footer">
                  <p class="title">{{car_tab.footer.title}}</p>
                  <p class="description">{{car_tab.footer.description}} {{formatPrice(formula.L30)}} kr.</p>
                  <a :href="car_tab.footer.button.url" class="button">{{car_tab.footer.button.title}}</a> 
                </div>
              </div>
              <p class="bottom-text">Vær opmærksom på, at den skattemæssige værdi skal genberegnes senest tre måneder efter første indregistrering og kan i den forbindelse ændre sig. Vi har skrevet en udførlig <a href="#">Introduktion til genberegning.</a></p>
            </div>

          </div>
      </div>
    </div>
  </div>

</template>

<script>


import ResultBox from './components/ResultBox.vue';
import SliderInput from './components/SliderInput.vue';
import PercentInput from './components/PercentInput.vue';
import {gsap, Quad} from 'gsap';
import AbAmount from '../../leasing/Showroom/src/common/ab_amount';
import InfoButton from '../info-button/InfoButton.vue';

export default {
  props: ['block', 'cmsGlobalData'],
  components: {
    'ResultBox' : ResultBox,
    'SliderInput' : SliderInput,
    'PercentInput' : PercentInput,
    'InfoButton' : InfoButton
  },
  data() {
    return {
      title: null,
      description: null,
      currentTab: 'tax',
      openCalculator: false,
      openedCalculator: false,
      abAmount: null,
      tax_tab: {},
      car_tab: {},
      tax_description: null,
      car_description: null,
      tax_tab_title: null,
      car_tab_title: null,
      tax_tab_description: null,
      car_tab_description: null,

      formula: {
        "L25" : 670000,
        "L26" : 0.5,
        "L27" : 1000,
        "L28" : 0.15,
        "L29" : 1000,

        "L47" : 1000,
        "L48" : 0.50,
        "L49" : 1000,
        "L50" : 0.15,
        "L51" : 1000,


        "I38" : "?",
        "J39" : "?",
        "K38" : "?",
      }

    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
  methods: {
    formatPrice(value) {
        if (value === undefined) {
            return "0";
        }

        var cents = Math.round(value%1*100);
        var sCents = "";
        if (cents>0 && cents<10) { sCents = ",0"+cents; }
        if (cents>=10) { sCents = ","+cents; }
        
        return this.abAmount.format(value)+"";
    },

    prepareContent: function(){
      let calculator = this.cmsGlobalData.calculator;

      this.title = calculator.title;
      this.description = calculator.description;
      this.tax_tab_title = calculator.tax_calculator.tab_title;
      this.car_tab_title = calculator.car_calculator.tab_title;
      this.tax_tab_description = calculator.car_calculator.tab_description;
      this.car_tab_description = calculator.tax_calculator.tab_description;
      this.car_description = calculator.car_calculator.description;
      this.tax_description = calculator.tax_calculator.description;


      this.tax_tab.f1_title = calculator.tax_calculator.fields[0].title;
      this.tax_tab.f2_title = calculator.tax_calculator.fields[1].title;
      this.tax_tab.f3_title = calculator.tax_calculator.fields[2].title;
      this.tax_tab.f4_title = calculator.tax_calculator.fields[3].title;
      this.tax_tab.f5_title = calculator.tax_calculator.fields[4].title;

      this.tax_tab.f1_info = calculator.tax_calculator.fields[0].info;
      this.tax_tab.f2_info = calculator.tax_calculator.fields[1].info;
      this.tax_tab.f3_info = calculator.tax_calculator.fields[2].info;
      this.tax_tab.f4_info = calculator.tax_calculator.fields[3].info;
      this.tax_tab.f5_info = calculator.tax_calculator.fields[4].info;

      this.tax_tab.f1_from = calculator.tax_calculator.fields[0].from*1;
      this.tax_tab.f2_from = calculator.tax_calculator.fields[1].from*1;
      this.tax_tab.f3_from = calculator.tax_calculator.fields[2].from*1;
      this.tax_tab.f4_from = calculator.tax_calculator.fields[3].from*1;
      this.tax_tab.f5_from = calculator.tax_calculator.fields[4].from*1;

      this.tax_tab.f1_to = calculator.tax_calculator.fields[0].to*1;
      this.tax_tab.f2_to = calculator.tax_calculator.fields[1].to*1;
      this.tax_tab.f3_to = calculator.tax_calculator.fields[2].to*1;
      this.tax_tab.f4_to = calculator.tax_calculator.fields[3].to*1;
      this.tax_tab.f5_to = calculator.tax_calculator.fields[4].to*1;

      this.tax_tab.f1_step = calculator.tax_calculator.fields[0].step*1;
      this.tax_tab.f2_step = calculator.tax_calculator.fields[1].step*1;
      this.tax_tab.f3_step = calculator.tax_calculator.fields[2].step*1;
      this.tax_tab.f4_step = calculator.tax_calculator.fields[3].step*1;
      this.tax_tab.f5_step = calculator.tax_calculator.fields[4].step*1;

      this.tax_tab.f1_value = calculator.tax_calculator.fields[0].value*1;
      this.tax_tab.f2_value = calculator.tax_calculator.fields[1].value*1;
      this.tax_tab.f3_value = calculator.tax_calculator.fields[2].value*1;
      this.tax_tab.f4_value = calculator.tax_calculator.fields[3].value*1;
      this.tax_tab.f5_value = calculator.tax_calculator.fields[4].value*1;

      this.tax_tab.footer_text = calculator.tax_calculator.footer_text;
      this.tax_tab.result_info = calculator.tax_calculator.result_info;
      this.tax_tab.results = calculator.tax_calculator.result;



      this.formula.L25 = this.tax_tab.f1_value;
      this.formula.L26 = this.tax_tab.f3_value/100;
      this.formula.L27 = this.tax_tab.f2_value;
      this.formula.L28 = this.tax_tab.f4_value/100;
      this.formula.L29 = this.tax_tab.f5_value;



      this.car_tab.f1_title = calculator.car_calculator.fields[0].title;
      this.car_tab.f2_title = calculator.car_calculator.fields[1].title;
      this.car_tab.f3_title = calculator.car_calculator.fields[2].title;
      this.car_tab.f4_title = calculator.car_calculator.fields[3].title;
      this.car_tab.f5_title = calculator.car_calculator.fields[4].title;

      this.car_tab.f1_info = calculator.car_calculator.fields[0].info;
      this.car_tab.f2_info = calculator.car_calculator.fields[1].info;
      this.car_tab.f3_info = calculator.car_calculator.fields[2].info;
      this.car_tab.f4_info = calculator.car_calculator.fields[3].info;
      this.car_tab.f5_info = calculator.car_calculator.fields[4].info;

      this.car_tab.f1_from = calculator.car_calculator.fields[0].from*1;
      this.car_tab.f2_from = calculator.car_calculator.fields[1].from*1;
      this.car_tab.f3_from = calculator.car_calculator.fields[2].from*1;
      this.car_tab.f4_from = calculator.car_calculator.fields[3].from*1;
      this.car_tab.f5_from = calculator.car_calculator.fields[4].from*1;

      this.car_tab.f1_to = calculator.car_calculator.fields[0].to*1;
      this.car_tab.f2_to = calculator.car_calculator.fields[1].to*1;
      this.car_tab.f3_to = calculator.car_calculator.fields[2].to*1;
      this.car_tab.f4_to = calculator.car_calculator.fields[3].to*1;
      this.car_tab.f5_to = calculator.car_calculator.fields[4].to*1;

      this.car_tab.f1_step = calculator.car_calculator.fields[0].step*1;
      this.car_tab.f2_step = calculator.car_calculator.fields[1].step*1;
      this.car_tab.f3_step = calculator.car_calculator.fields[2].step*1;
      this.car_tab.f4_step = calculator.car_calculator.fields[3].step*1;
      this.car_tab.f5_step = calculator.car_calculator.fields[4].step*1;

      this.car_tab.f1_value = calculator.car_calculator.fields[0].value*1;
      this.car_tab.f2_value = calculator.car_calculator.fields[1].value*1;
      this.car_tab.f3_value = calculator.car_calculator.fields[2].value*1;
      this.car_tab.f4_value = calculator.car_calculator.fields[3].value*1;
      this.car_tab.f5_value = calculator.car_calculator.fields[4].value*1;

      this.car_tab.footer_text = calculator.car_calculator.footer_text;
      this.car_tab.footer = calculator.car_calculator.footer;
      this.car_tab.results = calculator.car_calculator.result;


      this.formula.L47 = this.car_tab.f1_value;
      this.formula.L48 = this.car_tab.f3_value/100;
      this.formula.L49 = this.car_tab.f2_value;
      this.formula.L50 = this.car_tab.f4_value/100;
      this.formula.L51 = this.car_tab.f5_value;


      this.formula.C4 = calculator.excel_fields.percents[0].f2*1/100; this.formula.D4 = calculator.excel_fields.percents[0].f3*1/100;
      this.formula.C5 = calculator.excel_fields.percents[1].f2*1/100; this.formula.D5 = calculator.excel_fields.percents[1].f3*1/100;
      this.formula.C6 = calculator.excel_fields.percents[2].f2*1/100; this.formula.D6 = calculator.excel_fields.percents[2].f3*1/100;
      this.formula.C7 = calculator.excel_fields.percents[3].f2*1/100; this.formula.D7 = calculator.excel_fields.percents[3].f3*1/100;
      this.formula.C8 = calculator.excel_fields.percents[4].f2*1/100; this.formula.D8 = calculator.excel_fields.percents[4].f3*1/100;

      this.formula.C13 = calculator.excel_fields.supplement[0].f2*1;
      this.formula.C14 = calculator.excel_fields.supplement[1].f2*1;
      this.formula.C15 = calculator.excel_fields.supplement[2].f2*1;
      this.formula.C16 = calculator.excel_fields.supplement[3].f2*1;
      this.formula.C17 = calculator.excel_fields.supplement[4].f2*1;

      this.calculate();
    },
    getDimensions() {
    },
    onCloseCalculatorClick(e){
      e.preventDefault();
      this.openCalculator = false;
    },
    onTaxTabClick(e){
      e.preventDefault();
      this.currentTab = "tax";
      this.openCalculator = true;
    },
    onCarTabClick(e){
      e.preventDefault();
      this.currentTab = "car";
      this.openCalculator = true;
    },
    inputChanged(val, field){
      val = Math.round(val);
      if (field=="L26" || field=="L28" || field=="L50" || field=="L48"){
        val = val / 100;
      }
       this.formula[field] = val;
       this.calculate();
    },
    calculate(){
      var f = {
        "L25" : this.formula.L25,
        "L26" : this.formula.L26,
        "L27" : this.formula.L27,
        "L28" : this.formula.L28,
        "L29" : this.formula.L29,

        "L47" : this.formula.L47,
        "L48" : this.formula.L48,
        "L49" : this.formula.L49,
        "L50" : this.formula.L50,
        "L51" : this.formula.L51,

        "C4" : this.formula.C4 ? this.formula.C4 : 0.245 , "D4" : this.formula.D4 ? this.formula.D4 : 0.205,
        "C5" : this.formula.C5 ? this.formula.C5 : 0.240 , "D5" : this.formula.D5 ? this.formula.D5 : 0.210,
        "C6" : this.formula.C6 ? this.formula.C6 : 0.235 , "D6" : this.formula.D6 ? this.formula.D6 : 0.215,
        "C7" : this.formula.C7 ? this.formula.C7 : 0.230 , "D7" : this.formula.D7 ? this.formula.D7 : 0.220,
        "C8" : this.formula.C8 ? this.formula.C8 : 0.225 , "D8" : this.formula.D8 ? this.formula.D8 : 0.225,

        "C13" : this.formula.C13 ? this.formula.C13 : 150,
        "C14" : this.formula.C14 ? this.formula.C14 : 250,
        "C15" : this.formula.C15 ? this.formula.C15 : 350,
        "C16" : this.formula.C16 ? this.formula.C16 : 500,
        "C17" : this.formula.C17 ? this.formula.C17 : 600,

        "C23" : 3.0,
        "C24" : 9.7,
        "C25" : 16.8,
        "C26" : 24.4,
        "C27" : 36.9,

        "H6" : 400000,
        "H7" : 440000,
        "H8" : 666.67
      };

      f.H11 = 300000*f.C4/100/12;
      f.H12 = (f.H6 - 300000) * f.D4 / 100 / 12;
      f.H13 = (f.H11 + f.H12);
      f.H14 = f.H8;
      f.H15 = f.H14/12;
      f.H16 = f.H15 + f.H15 * f.C13 / 100;
      f.H17 = f.H13 + f.H16;


      f.I11 = 300000*f.C4/100/12;
      f.I12 = (f.H7 - 300000) * f.D4 / 100 / 12;
      f.I13 = (f.I11 + f.I12);
      f.I14 = f.H8;
      f.I15 = f.I14/12;
      f.I16 = f.I15 + f.I15 * f.C13 / 100;
      f.I17 = f.I13 + f.I16;
      
      f.J11 = 300000*f.C5/100/12;
      f.J12 = (f.H7 - 300000) * f.D5 / 100 / 12;
      f.J13 = (f.J11 + f.J12);
      f.J14 = Math.ceil(Math.ceil(f.H8 * (f.C23 / 100) + f.H8) / 10)*10;
      f.J15 = f.J14/12;
      f.J16 = f.J15 + f.J15 * f.C13 / 100;
      f.J17 = f.J13 + f.J16;

      f.K11 = 300000*f.C6/100/12;
      f.K12 = (f.H7 - 300000) * f.D6 / 100 / 12;
      f.K13 = (f.K11 + f.K12);
      f.K14 = Math.ceil(Math.ceil(f.H8 * (f.C24 / 100) + f.H8) / 10)*10;
      f.K15 = f.K14/12;
      f.K16 = f.K15 + f.K15 * f.C13 / 100;
      f.K17 = f.K13 + f.K16;

      f.L11 = 300000*f.C7/100/12;
      f.L12 = (f.H7 - 300000) * f.D7 / 100 / 12;
      f.L13 = (f.L11 + f.L12);
      f.L14 = Math.ceil(Math.ceil(f.H8 * (f.C25 / 100) + f.H8) / 10)*10;
      f.L15 = f.L14/12;
      f.L16 = f.L15 + f.L15 * f.C13 / 100;
      f.L17 = f.L13 + f.L16;
      
      f.M11 = 300000*f.C8/100/12;
      f.M12 = (f.H7 - 300000) * f.D8 / 100 / 12;
      f.M13 = (f.M11 + f.M12);
      f.M14 = Math.ceil(Math.ceil(f.H8 * (f.C26 / 100) + f.H8) / 10)*10;
      f.M15 = f.M14/12;
      f.M16 = f.M15 + f.M15 * f.C13 / 100;
      f.M17 = f.M13 + f.M16;

      f.N11 = 300000*f.C8/100/12;
      f.N12 = (f.H7 - 300000) * f.D8 / 100 / 12;
      f.N13 = (f.N11 + f.N12);
      f.N14 = f.N8;
      f.N15 = f.N14/12;
      f.N16 = f.N15 + f.N15 * f.C13 / 100;
      f.N17 = f.N13 + f.N16;

      f.N29 = 160000;
      f.N30 = 300000;

      const percentUnder = f.C7;
      const percentOver = f.D7;

      f.M34 = f.L25 <= f.N30 ? f.L25 * percentUnder / 12: f.N30 * percentUnder / 12 + (f.L25 - f.N30 ) * percentOver / 12;

      f.I34 = f.L25 > f.N29 * percentUnder / 12 ? f.M34 : f.N29 * percentUnder / 12;
      f.I35 = f.L29 / 12;

      f.I36 = f.I35 + f.I34;
      f.I37 = f.L27;
      
      f.J35 = f.L29 / 12;
      
      f.J37 = f.L27;

      f.M38 = (f.I36 - f.I37) * f.L26 + f.I37;      

      f.N34 = f.L25 * (1 + f.L28) <= f.N30 ? f.L25 * (1 + f.L28) * percentUnder / 12 : f.N30 * percentUnder / 12 + (f.L25 * (1 + f.L28) - f.N30) * percentOver / 12;

      f.J34 = f.L25 > f.N29 * percentUnder / 12 ? f.N34 : f.N29 * percentUnder / 12;
      f.J36 = f.J35 + f.J34;

      f.N38 = (f.J36 - f.J37) * f.L26 + f.J37;

      f.O34 = f.L25 * (1 + f.L28) * 0.75 <= f.N30 ? f.L25 * (1 + f.L28) * 0.75 * f.C8 / 12 : f.N30 * f.C8 / 12 + (f.L25 * (1 + f.L28) * 0.75 - f.N30) * f.D8 / 12;

      f.J38 = f.J36 >= f.J37 ? f.N38 : f.J36;

      f.I38 = f.I36 >= f.I37 ? f.M38 : f.I36;
      f.J39 = f.I38 > f.J38 ? f.I38 : f.J38;

      f.O35 = (f.L29  / (1 + f.C15 / 100)*(1 + f.C17 / 100)) / 12;
      f.K34 = f.L25 > f.N29 * percentUnder / 12 ? f.O34 : f.N29 * percentUnder / 12;
      f.K35 = f.O35;

      f.K36 = f.K35 + f.K34;
      f.K37 = f.L27;
      f.O38 = (f.K36 - f.K37) * f.L26 + f.K37;
      
      f.K38 = f.K36 >= f.K37 ? f.O38 : f.K36;



      f.J59 = f.L49;
      f.J60 = f.L47;
      
      f.J57 = f.L51 / 12;
      f.J58 = (f.J60 - f.J59) / f.L48 + f.J59;
      f.J56 = f.J58 - f.J57;
      f.M50 = 0;

      //second tab result
      f.L30 = ((f.J56 - 300000 * (percentUnder / 12)) * (12 / percentOver) + 300000) / (1 + (f.L50 < f.M50 ? f.M50 : f.L50));

      this.formula = f;
    }
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );
    this.$watch(
      () => this.cmsGlobalData,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );
    this.$watch(
      () => this.openCalculator,
      (toParams, previousParams) => {
        var th = this;

        if (window.innerWidth<768){
          if (toParams){
            th.openedCalculator = toParams;
            gsap.to(".calculator-content", {duration: 0, opacity: 1, x: window.innerWidth});
            gsap.to(".calculator-content", {duration: 0.3, opacity: 1, x: 0, ease: Quad.easeOut});
          }else{
            gsap.to(".calculator-content", {duration: 0.3, opacity: 1, x: window.innerWidth, ease: Quad.easeIn, onComplete: function(){
              th.openedCalculator = false;
              gsap.to(".calculator-content", {duration: 0, opacity: 1, x: 0});
            }});
          }
        }else{
          this.openedCalculator = toParams;
        }

      }
    );

    this.calculate();
    this.abAmount = new AbAmount();

  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.calculator-block{
  margin: 124px 20px;
  background: rgba(105, 189, 182, 0.09);
  border-radius: 20px;
  padding: 96px 0px;
  padding-bottom: 104px;

  .tab-bar{
    background: #FFFFFF;
    box-shadow: 0px 10px 22px -24px rgba(31, 72, 68, 0.12);
    border-radius: 20px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 12px;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;

    

    .tab{
      display: flex;
      width: calc(50% - 4px);
      justify-content: flex-start;
      align-items: center;
      background: transparent;
      border-radius: 12px;
      padding: 16px 24px;
      cursor: pointer;

      &.active{
        background: rgba(105, 189, 182, 0.09);
      }
      .icon{
        width: 54px;
        min-width: 54px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .text{
        padding-left: 24px;
      }
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        color: #1F4743;
        margin-bottom: 4px;
        display: block;
      }
      .description{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        color: #1F4743;
        display: block;
      }
      .button{
        margin-top: 20px;
        display: none;
      }
    }
  }
  .mobile-head{
    display: none;
  }
  .block-title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: #1F4743;
    margin-bottom: 16px;
  }
  .block-description{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #4C6C69;
    margin-bottom: 56px;
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }
  .calculator-content{
    background: #FFFFFF;
    box-shadow: 0px 10px 22px -24px rgba(31, 72, 68, 0.12);
    border-radius: 20px;
    padding: 28px 32px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    box-sizing: border-box;

    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    .bottom-text{
      display: none;
    }
    .left-side{
      width: calc(100% - 436px);
      width: calc(60% - 22px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .description{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #4C6C69;
      }
      .calculator-inner{
        margin-top: 48px;
        margin-bottom: 40px;
        .row{
          display: block;
          margin: 0px;
          width: 100%;
          margin-bottom: 32px;

          &:last-child{
            margin-bottom: 0px;
          }
        }
      }
      .bottom-text{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 133%;
        letter-spacing: -0.01em;
        color: #4C6C69;
        display: block;
        a{
          color: #4C6C69;
          font-weight: 700;
          border-bottom: 1px solid #4C6C69;
        }
      }
    }
    .right-side{
      width: calc(40% - 22px);
      background: rgba(105, 189, 182, 0.09);
      border-radius: 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      
      
      .label{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #4C6C69;
        padding: 16px 32px;

        vertical-align: top;
        
        .info-button{
          vertical-align: top;
          margin-top: 7px;
          margin-left: 8px;
        }
        
      }
      .inner{
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .bottom-section{
        background: #E8F5F4;
        border-radius: 0px 0px 8px 8px;
        box-sizing: border-box;
        padding: 40px 16px;
        text-align: center;
        .title{
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          color: #1F4743;
          margin-bottom: 4px;
        }
        .description{
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 133%;
          text-align: center;
          letter-spacing: -0.01em;
          color: #4C6C69;
          margin-bottom: 20px;
        }
      }
      

      .result-box-2{
        .title{
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 133%;
          text-align: center;
          color: #4C6C69;
          margin-bottom: 12px;
        }
        .value{
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 130%;
          text-align: center;
          color: #1F4743;
          margin-bottom: 12px;
        }
        .description-title{
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 130%;
          text-align: center;
          color: #4C6C69;
          margin-bottom: 2px;
        }
        .description{
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 133%;
          text-align: center;
          letter-spacing: -0.01em;
          color: #4C6C69;
        }
        .info-button{
          margin-top: 4px;
          margin-left: 8px;
        }
      }
    }
  }
  .result-boxes{
    padding: 16px 32px;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
}


@media (max-width: 1000px) {
  .calculator-block{
    margin-top: 96px;
    margin-bottom: 96px;
    padding-bottom: 32px;
    padding-top: 88px;
    .tab-bar{
      .tab{
        display: block;
        .text{
          padding-left: 0px;
        }
      }
    }
    ::v-deep .top-side{
      display: block;
      .title-wrap{
        width: 100%;
        margin-bottom: 8px;
      }
      .res{
        width: 100%;
        text-align: left;
        .value{
          text-align: left;
        }
      }
    }
    ::v-deep .percent-input{
      display: block;
      .title-wrap{
        width: 100%;
        margin-bottom: 8px;
      }
      .title{
        font-size: 14px;
      }
      .input-wrap{
        justify-content: flex-start;
      }
      .value{
        font-size: 20px;
        line-height: 28px;
      }
    }
    ::v-deep .slider-input{
      .title{
        font-size: 14px;
      }
      .value{
        font-size: 20px;
        line-height: 28px;
      }
    }
    ::v-deep .result-box{
      .title{
        font-size: 16px;
      }
      .value{
        font-size: 24px;
        line-height: 130%;
      }
    } 


    .calculator-content{
      .right-side{
        .result-box-2{  
          .title{
            font-size: 16px;
            line-height: 120%;
          }
          .value{
            font-size: 24px;
            line-height: 130%;
          }
          .description-title{
            font-size: 16px;
            line-height: 120%;
          }
          .description{
            font-size: 12px;
            line-height: 133%;
          }
        }
      }
    }
  }
}

@media (max-width: #{$screen-tablet}) {
  .calculator-block{
    
    .tab-bar{
      padding: 0px;
      margin: 0px;
      .tab{
        &:last-child{
          margin-bottom: 0px;
        }
        .text{
          .title{
            font-size: 20px;
            line-height: 130%;
          }
          .description{
            font-size: 14px;
            line-height: 133%;
          }
        }
      }
    }
    .calculator-content{
      .right-side{
        .label{
          
          padding: 12px 24px;
          font-size: 16px;
          line-height: 135%;
          background: #E1F2F0;
          border-radius: 12px 12px 0px 0px;

           .info-button{
            vertical-align: top;
            margin-top: 4px;
            margin-left: 8px;
          } 
        }
        .result-boxes{
            padding: 36px 24px;
        }
      }
    }
  }

  .calculator-block{
    .tab-bar{
      display: block;
      background: transparent;

      .tab{
        display: block;
        width: 100%;
        background: rgba(105, 189, 182, 0.09);
        margin-bottom: 24px;
        padding: 24px;
        .text{
          .title{
            font-size: 20px;
            line-height: 130%;
          }
          .description{
            font-size: 14px;
            line-height: 133%;
          }
        }
        .button{
          display: inline-block;
        }
      }
    }
    &.open-mobile{
      .calculator-content{
        display: block;
      }
    }

    .calculator-content{
      display: none;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: #FFF;
      z-index: 1000;
      border-radius: 0px;
      flex-direction: column;
      overflow-y: scroll;

      padding: 20px;
      
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      .mobile-head{
        display: block;

        .logo-wrap{
          max-width: 102px;
          display: block;
          margin-bottom: 42px;
        }
        .back-arrow{
          display: inline-block;
          margin-bottom: 32px;
          cursor: pointer;
        }
        .block-title{
          font-size: 32px;
          line-height: 130%;
          margin-bottom: 12px;
        }
        .block-description{
          font-size: 18px;
          line-height: 133%;
        }
      }

      .bottom-text{
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 133%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #4C6C69;
        margin-top: 32px;
      }
      .left-side{
        width: 100%;
        .bottom-text{
          display: none;
        }
      }
      .right-side{
        width: 100%;
      }
    }
  }

}
@media (max-width: 700px) {
  .calculator-block{

    .tab-bar{
      .tab{
        display: block;
        .text{
          padding-left: 0px;
        }
      }
    }
  }
}

@media (max-width: #{$screen-mobile}) {
  .calculator-block{
    margin-top: 112px;
    margin-bottom: 112px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    background: transparent;
  }
}

</style>