<template>


  <div class="soft-conversion-block">
    <div class="wrap">
      <div class="block">
        <div class="left-side">
          <h2 class="block-title">{{title}}</h2>
          <div class="block-description" v-html="description"></div>
          <div class="icon-list" v-if="icons">
            <div class="item" v-for="(item, index) in icons" :key="'scb-list-1-'+index">
               <img class="icon" :src="item.icon" alt="icon" />
               <p class="title">{{item.title}}</p>
               <p class="description">{{item.description}}</p>
            </div>
          </div>
          <div class="form-wrap" id="conversion-form-wrap">
            <form action="" method="POST" v-on:submit="mailchimpSubmit">
              <div class="double-field">
                <div class="field">
                  <InputField :placeholder="'Navn'" :name="'name'" :value="nameValue" type="text" theme="nr2" @focus="onFocusNameField" @changed="onChangedNameField" />
                  <span class="error-label" v-if="nameErrLabel">{{nameErrLabel}}</span>
                </div>
                <div class="field">
                  <InputField :placeholder="'Firmanavn'" :name="'company'" :value="companyValue" type="text" theme="nr2" @focus="onFocusCompanyField" @changed="onChangedCompanyField"  />
                  <span class="error-label" v-if="companyErrLabel">{{companyErrLabel}}</span>
                </div>
              </div>
              <div class="field">
                <InputField :placeholder="'Email'" :name="'email'" :value="emailValue" type="text" theme="nr2" @focus="onFocusEmailField" @changed="onChangedEmailField"  />
                <span  class="error-text" v-if="emailErrLabel">{{emailErrLabel}}</span>
              </div>

              <p v-if="mailchimpResponseVisible" :class="'mailchimp-response '+mailchimpResponseType" v-html="mailchimpResponse"></p>

              <div class="button-wrap" v-if="button && button.url">
                <button class="button" href="#" type="submit" :disabled="loading" v-html="(!loading ? 'Tilmeld nyhedsbrev' : 'Submiting...')"></button>
              </div>
            </form>
          </div>
        </div><div class="right-side">
          <div class="icon-list" v-if="icons">
            <div class="item" v-for="(item, index) in icons" :key="'scb-list-2-'+index">
               <img class="icon" :src="item.icon" alt="icon" />
               <p class="title">{{item.title}}</p>
               <p class="description">{{item.description}}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import InputField from "@/components/ui/input-field/InputField.vue";

export default {
  props: ['block'],
  components: {
    'InputField' : InputField
  },
  data() {
    return {
      title: "Få tilbud og nyheder som den første",
      description: "<p>Tilmeld dig vores nyhedsbrev, og få gode råd og seneste nyt fra Opendo direkte i indbakken.</p>",
      button: {"url": "#", "title": "Tilmeld nyhedsbrev", "target": null},
      icons: [
        {
          'icon': '/wp-api/wp-content/uploads/2022/03/illustration-traer.svg',
          'title' : "Grøn omstilling",
          'description' : "Hold dig opdateret på de nyeste tendenser inden for den grønne omstilling."
        },{
          'icon': '/wp-api/wp-content/uploads/2022/03/illustration-traer.svg',
          'title' : "Grøn omstilling",
          'description' : "Hold dig opdateret på de nyeste tendenser inden for den grønne omstilling."
        },{
          'icon': '/wp-api/wp-content/uploads/2022/03/illustration-traer.svg',
          'title' : "Grøn omstilling",
          'description' : "Hold dig opdateret på de nyeste tendenser inden for den grønne omstilling."
        }
      ],
      nameErrLabel: '',
      companyErrLabel: '',
      emailErrLabel: '',

      nameValue: '',
      companyValue: '',
      emailValue: '',

      mailchimpResponseVisible: false,
      mailchimpResponseType: null,
      mailchimpResponse: null,

      loading: false,
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
    window.removeEventListener('mailchimpConversionVueResponse', this.onMailchimpResponse);
  },
  mounted(){
    this.prepareContent();
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
  methods: {
    onMailchimpResponse: function(e){
      this.loading = false;

      if(window.mailchimpConversionVueResponse){
        var result = window.mailchimpConversionVueResponse.result;
        var msg = window.mailchimpConversionVueResponse.msg;

        if (msg.indexOf("Please enter a value")>=0){
          this.mailchimpResponse = "Please fill out fields";
        }else if (msg.indexOf("This email cannot be added to this list")>=0){
          this.mailchimpResponse = "This email cannot be added to this list";
        }else{
          this.mailchimpResponse = msg;
        }

        this.mailchimpResponseVisible = true;

        this.mailchimpResponseType = result;
        if (result == "error"){
        }
        if (result == "success"){
          this.mailchimpResponse = "<span>Du er nu tilmeldt Opendos nyhedsbrev. Du kan finde mere information om, hvordan Opendo behandler dine personoplysninger i Opendos <a href='https://www.opendo.dk/wp-api/wp-content/uploads/2022/08/Opendo-persondatapolitik.pdf' target='_blank'>persondatapolitik</a>.</span>";
          this.success = true;
        }
      }
    },
    prepareContent: function(){
      this.title = this.block.title.value;
      this.description = this.block.description.value;

      var icons = [];
      if (this.block.icons){
        var iconsCount = this.block.icons.value;
        for (var i=0; i<iconsCount; i++){
          var icon = this.block['icons_'+i+'_icon'].value.url;
          var iconTitle = this.block['icons_'+i+'_title'].value;
          var iconDescription = this.block['icons_'+i+'_description'].value;
          icons.push({'title': iconTitle, 'description': iconDescription, 'icon': icon});
        }
      }
      this.icons = icons;
    },
    onFocusNameField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedNameField(v){
      this.nameValue = v;
    },
    onFocusCompanyField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedCompanyField(v){
      this.companyValue = v;
    },
    onFocusEmailField(){
      this.mailchimpResponseVisible = false;
    },
    onChangedEmailField(v){
      this.emailValue = v;
    },
    mailchimpSubmit(e){
      e.preventDefault();

      let formData = new FormData(e.target);
      let email = formData.get('email');
      let name = formData.get('name');
      let company = formData.get('company');
      let form = document.querySelector("#mc_embed_signup #mc-embedded-subscribe-form");
      let ok = true;

      if (email && form) {
        let e_inp = form.querySelector("input[name='EMAIL']");
        if (e_inp) {
          e_inp.value = email;
        }

        let n_inp = form.querySelector("input[name='FNAME']");
        let c_inp = form.querySelector("input[name='MMERGE5']");
        let p_checkbox = form.querySelector("input[name='group[384117][1]']");
        let e_checkbox = form.querySelector("input[name='group[384117][2]']");

        if (n_inp) {
          n_inp.value = name;
        }
        if (c_inp) {
          c_inp.value = company;
        }
        if (p_checkbox) {
          p_checkbox.checked = false;
        }
        if (e_checkbox) {
          e_checkbox.checked = true;
        }
      } else {
        ok = false;
        this.emailErrLabel = "Du skal udfylde dette felt";
      }

      if (ok) {
        this.loading = true;
        form.querySelector('#responseFunction').value = 'mailchimConversionResponse';
        form.querySelector("#mc_embed_signup #mc-embedded-subscribe").click();
      }
    },
    getDimensions() {
    },
  },
  created() {
    var th = this;
    this.$watch(
      () => this.block,
      (toParams, previousParams) => {
        this.prepareContent();
      }
    );

    window.addEventListener('mailchimpConversionVueResponse', this.onMailchimpResponse);
  },
}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
form.hidden{
  display: none;
}
.soft-conversion-block{
  margin-top: 122px;
  margin-bottom: 122px;

  .icon-list{
    display: flex;
    flex-direction: row;
    align-items: flex-start;


    .item{
      margin-left: 20px;
      margin-right: 20px;
      width: calc(33.33% - 40px);
      text-align: center;
    }
    .icon{
      display: block;
      line-height: 0px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      height: 74px;
      width: auto;

    }
    .title{
      margin-bottom: 4px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      text-align: center;
      color: #4C6C69;
    }
    .description{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      text-align: center;
      color: #4C6C69;

    }
  }
  .block-title{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #1F4743;
    margin-bottom: 20px;
  }
  .block-description{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4C6C69;
  }
  .block{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .left-side{
    background: #FFFFFF;
    box-shadow: 0px 11px 41px -10px rgba(31, 72, 68, 0.09);
    border-radius: 20px;
    margin-right: 54px;
    width: 444px;
    padding: 48px;
    box-sizing: border-box;
    .icon-list{
      display: none;
    }
  }
  .right-side{
    display: block;
    width: calc(100% - 444px - 54px);
  }
  .button-wrap{
    text-align: center;
    margin-top: 32px;
  }
  .form-wrap{
    margin-top: 32px;
  }

  .field{
    margin-bottom: 16px;
  }
  .double-field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .field{
      width: calc(50% - 8px);

    }
  }


  .mailchimp-response{
    ::v-deep a{
      text-decoration: underline;
    }
    &.error{
      color: #DF4343;
      ::v-deep a{
        color: #DF4343;
      }
    }
    &.success{
      color: #69BDB6;
      ::v-deep a{
        color: #69BDB6;
      }
    }
    &.info{
      color: #69BDB6;
      ::v-deep a{
        color: #69BDB6;
      }
    }
    max-width: 570px;
    font-size: 12px;
    padding-left: 4px;
    display: block;
    text-align: left;
    margin-top: 4px;
  }
}



@media (max-width: 1000px) {
  .soft-conversion-block{
    .form-wrap{
      max-width: 482px;
      margin-left: auto;
      margin-right: auto;
    }
    .left-side{
      width: 100%;
      margin: 0px;
      text-align: center;
      .icon-list{
        display: flex;
        margin-top: 48px;
        margin-bottom: 48px;
      }
    }
    .right-side{
      display: none;
      .icon-list{
        display: none;
      }
    }

    .block-title{
      font-size: 24px;
      line-height: 130%;
      margin-bottom: 12px;
    }
    .block-description{
      font-size: 16px;
      line-height: 135%;
    }
  }
}

@media (max-width: #{$screen-mobile}) {
 .soft-conversion-block{
    .icon-list{
      .item{
        width: 100%;
        margin: 0px;
        margin-bottom: 48px;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }

    .left-side{

      .icon-list{
        display: block;

      }
    }
  }
}

</style>