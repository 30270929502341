<template>
  <div v-if="cmsPageData.page.dynamic_page" class="dynamic-blocks">
    <template v-for="(group, index) in blocks">
      <section v-if="group.name == 'acf/hero'"
               :class="'section fade-on-enter hero-section hero-block-section op-fade-in-animation '">
        <template v-for="(block, index) in group.blocks">
          <div class="top-part" :class="(block.attrs.image.value.url ? '' : 'only-top') +(
          ((block.attrs.hero_type.value == 'contacts' && block.attrs.image.value.url) ? ' with-image ' : '') + 
          ((block.attrs.hero_type.value == 'contacts_video' && block.attrs.big_video.value.url) ? ' with-video ' : '')
        )">
            <div class="wrap">
              <div class="logo" v-if="block.attrs.logo && block.attrs.logo.value.url"><img
                  :src="block.attrs.logo.value.url"
                  :alt="block.attrs.logo.value.info ? block.attrs.logo.value.info.alt : ''"></div>
              <h1 class="h1-2 title">{{ block.attrs.title.value }}</h1>
              <div class="content" v-html="block.attrs.text.value"></div>
              <div class="contacts"
                   v-if="(block.attrs.hero_type.value == 'contacts' || block.attrs.hero_type.value == 'contacts_video') && (block.attrs.phone || block.attrs.email)">
                <a v-if="block.attrs.phone" :href="'tel:'+block.attrs.phone.value.replace(/ /g,'')" class="phone"><span
                    class="icon"><svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1167_935)">
                  <path
                      d="M12.8705 22.5393L12.8805 22.5463C13.7446 23.0965 14.7705 23.3354 15.7887 23.2235C16.8069 23.1117 17.7565 22.6559 18.4805 21.9313L19.1095 21.3023C19.2489 21.163 19.3595 20.9976 19.4349 20.8156C19.5104 20.6335 19.5492 20.4384 19.5492 20.2413C19.5492 20.0442 19.5104 19.8491 19.4349 19.6671C19.3595 19.485 19.2489 19.3196 19.1095 19.1803L16.4565 16.5293C16.3172 16.3899 16.1518 16.2793 15.9698 16.2039C15.7877 16.1285 15.5926 16.0896 15.3955 16.0896C15.1985 16.0896 15.0033 16.1285 14.8213 16.2039C14.6392 16.2793 14.4738 16.3899 14.3345 16.5293C14.0532 16.8105 13.6718 16.9685 13.274 16.9685C12.8763 16.9685 12.4948 16.8105 12.2135 16.5293L7.97153 12.2863C7.69033 12.005 7.53235 11.6236 7.53235 11.2258C7.53235 10.8281 7.69033 10.4466 7.97153 10.1653V10.1653C8.11092 10.026 8.2215 9.8606 8.29694 9.67855C8.37238 9.4965 8.41121 9.30137 8.41121 9.1043C8.41121 8.90724 8.37238 8.71211 8.29694 8.53006C8.2215 8.34801 8.11092 8.1826 7.97153 8.0433L5.31953 5.3923C5.03824 5.1111 4.65678 4.95312 4.25903 4.95312C3.86129 4.95312 3.47982 5.1111 3.19853 5.3923L2.56853 6.0213C1.84412 6.74548 1.38845 7.69513 1.27681 8.71334C1.16518 9.73154 1.40425 10.7574 1.95453 11.6213L1.96053 11.6313C4.86671 15.9311 8.57021 19.6339 12.8705 22.5393V22.5393Z"
                      stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M11.75 8.25C12.9435 8.25 14.0881 8.72411 14.932 9.56802C15.7759 10.4119 16.25 11.5565 16.25 12.75"
                      stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.75 4.5C13.938 4.5 16.0365 5.36919 17.5836 6.91637C19.1308 8.46354 20 10.562 20 12.75"
                        stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M11.75 0.75C14.9326 0.75 17.9848 2.01428 20.2353 4.26472C22.4857 6.51516 23.75 9.5674 23.75 12.75"
                      stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1167_935">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                  </defs>
                  </svg>
                  </span><span class="label">{{ block.attrs.phone.value }}</span>
                </a>
                <a v-if="block.attrs.email" :href="'mailto:'+block.attrs.email.value" class="email"><span class="icon"><svg
                    width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 4.75H23V19.75H2V4.75Z" stroke="#1F4743" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path
                      d="M22.6618 5.30078L14.5178 11.5648C13.9396 12.0096 13.2304 12.2508 12.5008 12.2508C11.7713 12.2508 11.0621 12.0096 10.4838 11.5648L2.33984 5.30078"
                      stroke="#1F4743" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </span><span class="label">{{ block.attrs.email.value }}</span>
                </a>
              </div>
            </div>
          </div>

          <div class="bottom-part" v-if="block.attrs.hero_type.value == 'contacts' && block.attrs.image.value.url">
            <div class="wrap">
              <div class="image" v-if="block.attrs.image.value.url"><img :src="block.attrs.image.value.url"
                                                                         :alt="block.attrs.image.value.info ? block.attrs.image.value.info.alt : ''">
              </div>
            </div>
          </div>

          <div class="bottom-part"
               v-if="block.attrs.hero_type.value == 'contacts_video' && block.attrs.big_video.value.url">
            <div class="wrap">
              <div v-if="block.attrs.big_video.value.url" class="big-image">
                <a class="" v-if="block.attrs.block_url && block.attrs.block_url.value"
                   :href="block.attrs.block_url.value.url" :target="block.attrs.block_url.value.target">
                  <video inline autoplay muted loop>
                    <source :src="block.attrs.big_video.value.url" :type="block.attrs.big_video.value.mime_type">
                  </video>
                  <div class="tagline-wrap">
                    <span class="tagline"
                          v-if="block.attrs.video_tagline.value">{{ block.attrs.video_tagline.value }}</span>
                    <span class="arrow"><svg width="48" height="23" viewBox="0 0 48 23" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                      <path d="M37 1.5L47 11.5L37 21.5" stroke="white" stroke-width="2" stroke-miterlimit="10"
                            stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M47 11.5H1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                    </span>
                    <span class="arrow-small"><svg width="30" height="14" viewBox="0 0 30 14" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
                     <path d="M23 1L29 7L23 13" stroke="#4C6C69" stroke-miterlimit="10" stroke-linecap="round"
                           stroke-linejoin="round"/>
                    <path d="M28.9984 7H1.39844" stroke="#4C6C69" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    </svg>  
                    </span>
                  </div>
                </a>
                <div class="" v-else>
                  <video inline autoplay muted loop>
                    <source :src="block.attrs.big_video.value.url" :type="block.attrs.big_video.value.mime_type">
                  </video>
                  <div class="tagline-wrap">
                    <span class="tagline" v-if="block.attrs.video_tagline">{{ block.attrs.video_tagline.value }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </section>

      <section v-if="group.name === 'acf/hero2'"
               :class="'section fade-on-enter hero2-section hero2-block-section op-fade-in-animation '">
        <template v-for="(block, index) in group.blocks">
          <div class="wrap">
            <div class="top-part"
                 :style="(block.attrs.hero_type.value === 'image' && block.attrs.image?.value.url) ? ' background-image: url(' + block.attrs.image.value.url + ') ' : ''">

              <div v-if="block.attrs.hero_type.value === 'video'" class="video-container">
                <video inline autoplay muted loop>
                  <source :src="block.attrs.big_video?.value.url" :type="block.attrs.big_video?.value.mime_type">
                </video>
                <div class="side" :style="'background-color: ' + block.attrs.background_color?.value">
                  <h1 class="h1-2 title" :style="'color: ' + block.attrs.text_color?.value">{{
                      block.attrs.title.value
                    }}</h1>
                  <div class="content" :style="'color: ' + block.attrs.text_color?.value">{{ block.attrs.text?.value }}
                  </div>
                  <a v-if="block.attrs.show_button?.value == 1" class="button"
                     :href="block.attrs.button_link?.value.url">{{ block.attrs.button_title?.value }}</a>
                </div>
              </div>
              <div v-else class="side" :style="'background-color: ' + block.attrs.background_color?.value">
                <h1 class="h1-2 title" :style="'color: ' + block.attrs.text_color?.value">{{
                    block.attrs.title.value
                  }}</h1>
                <div class="content" :style="'color: ' + block.attrs.text_color?.value">{{ block.attrs.text?.value }}
                </div>
                <a v-if="block.attrs.show_button?.value == 1" class="button"
                   :href="block.attrs.button_link?.value.url">{{ block.attrs.button_title?.value }}</a>
              </div>
            </div>
          </div>
        </template>
      </section>

      <section v-if="group.name == 'textualContent'" class="section fade-on-enter op-fade-in-animation">
        <div class="wrap">
          <div class="textual-content" v-if="group.blocks">
            <template v-for="(block, index) in group.blocks">
              <HTMLFragment v-if="block.name == 'core/paragraph'" :html="block.html"></HTMLFragment>
              <HTMLFragment v-if="block.name == 'core/list'" :html="block.html"></HTMLFragment>
              <HTMLFragment v-if="block.name == 'core/table'" :html="block.html"></HTMLFragment>
              <HTMLFragment v-if="block.name == 'core/image'" :html="block.html"></HTMLFragment>
              <HTMLFragment v-if="block.name == 'core/quote'" :html="block.html"></HTMLFragment>
              <HTMLFragment v-if="block.name == 'core/heading'" :html="block.html"></HTMLFragment>


              <template v-if="block.name == 'acf/youtube-video'">
                <figure class="wp-block-image size-large">
                  <iframe width="100%" height="auto" style="aspect-ratio: 16/10;"
                          :src="'https://www.youtube.com/embed/'+block.attrs.video.value"></iframe>
                </figure>
              </template>

              <template v-if="block.name == 'acf/accordion'">
                <AccordionList :items="block.accordion"/>
              </template>

            </template>
          </div>
        </div>
      </section>


      <div v-if="group.name == 'acf/news-articles'" class="acf-block acf-news-articles related-green-section">
        <template v-for="(block, index) in group.blocks">
          <news-section v-if="block.attrs.news.list" :articles="block.attrs.news.list" :button="'Alle nyheder'"
                        :title="block.attrs.title ? block.attrs.title.value : 'Relaterede artikler'"/>
        </template>
      </div>


      <div v-if="group.name == 'acf/tagged-news-block'" class="acf-block acf-tagged-news-block">
        <template v-for="(block, index) in group.blocks">
          <tagged-news-block :block="block.attrs"/>
        </template>
      </div>

      <div v-if="group.name == 'acf/calculator-block'" class="acf-block acf-calculator-block">
        <template v-for="(block, index) in group.blocks">
          <calculator-block :block="block.attrs" :cmsGlobalData="cmsGlobalData"/>
        </template>
      </div>

      <div v-if="group.name == 'acf/cars-block'" class="acf-block acf-cars-block">
        <template v-for="(block, index) in group.blocks">
          <cars-block :block="block.attrs"/>
        </template>
      </div>

      <div v-if="group.name == 'acf/media-text-block'" class="acf-block acf-media-text-block">
        <template v-for="(block, index) in group.blocks">
          <media-text-block :block="block.attrs"/>
        </template>
      </div>


      <div v-if="group.name == 'acf/text-boxes-block'" class="acf-block acf-text-boxes-block">
        <template v-for="(block, index) in group.blocks">
          <text-boxes-block :block="block.attrs"/>
        </template>
      </div>
      <div v-if="group.name == 'acf/two-column-block'" class="acf-block acf-two-column-block">
        <template v-for="(block, index) in group.blocks">
          <two-column-block :block="block.attrs"/>
        </template>
      </div>
      <div v-if="group.name == 'acf/logo-slider-block'" class="acf-block acf-logo-slider-block">
        <template v-for="(block, index) in group.blocks">
          <logo-slider-block :block="block.attrs"/>
        </template>
      </div>
      <div v-if="group.name == 'acf/iframe-block'" class="acf-block acf-iframe-block">
        <template v-for="(block, index) in group.blocks">
          <iframe-block :block="block.attrs"/>
        </template>
      </div>

      <div v-if="group.name == 'acf/timeline-block'" class="acf-block acf-timeline-block">
        <template v-for="(block, index) in group.blocks">
          <timeline-block :block="block.attrs"/>
        </template>
      </div>
      <div v-if="group.name == 'acf/jobs-block'" class="acf-block acf-jobs-block">
        <template v-for="(block, index) in group.blocks">
          <jobs-block :block="block.attrs"/>
        </template>
      </div>
      <div v-if="group.name == 'acf/faq-block'" class="acf-block acf-faq-block">
        <template v-for="(block, index) in group.blocks">
          <faq-block :block="block.attrs"/>
        </template>
      </div>

      <div v-if="group.name == 'acf/soft-conversion-block'" class="acf-block acf-soft-conversion-block">
        <template v-for="(block, index) in group.blocks">
          <soft-conversion-block :block="block.attrs"/>
        </template>
      </div>

      <div v-if="group.name == 'acf/test-area-block'" class="acf-block acf-test-area-block">
        <template v-for="(block, index) in group.blocks">
          <test-area-block :block="block.attrs"/>
        </template>
      </div>


      <div v-if="group.name == 'acf/compare-table-block'" class="acf-block acf-compare-table-block">
        <template v-for="(block, index) in group.blocks">
          <compare-table-block :block="block.attrs"/>
        </template>
      </div>


      <div v-if="group.name == 'acf/car-bubble-block'" class="acf-block acf-car-bubble-block">
        <template v-for="(block, index) in group.blocks">
          <car-bubble-block :block="block.attrs"/>
        </template>
      </div>

      <div v-if="group.name == 'acf/anchor-block'" class="acf-block acf-anchor-block">
        <template v-for="(block, index) in group.blocks">
          <anchor-block :block="block.attrs"/>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import utils from "@/helpers";
import AccordionList from "@/components/ui/accordion-list-v2/AccordionList.vue";
import TaggedNewsBlock from "@/components/ui/tagged-news-block/TaggedNewsBlock.vue";
import CalculatorBlock from "@/components/ui/calculator-block/CalculatorBlock.vue";
import NewsSection from '@/components/ui/news-section/news-section';
import CarsBlock from "@/components/ui/cars-block/CarsBlock.vue";
import MediaTextBlock from "@/components/ui/media-text-block/MediaTextBlock.vue";
import TextBoxesBlock from "@/components/ui/text-boxes-block/TextBoxesBlock.vue";
import TwoColumnBlock from "@/components/ui/two-column-block/TwoColumnBlock.vue";
import LogoSliderBlock from "@/components/ui/logo-slider-block/LogoSliderBlock.vue";
import IframeBlock from "@/components/ui/iframe-block/IframeBlock.vue";
import FAQBlock from "@/components/ui/faq-block/FAQBlock.vue";
import SoftConversionBlock from "@/components/ui/soft-conversion-block/SoftConversionBlock.vue";
import TestAreaBlock from "@/components/ui/test-area-block/TestAreaBlock.vue";
import CompareTableBlock from "@/components/ui/compare-table-block/CompareTableBlock.vue";
import CarBubbleBlock from "@/components/ui/car-bubble-block/CarBubbleBlock.vue";
import AnchorBlock from "@/components/ui/anchor-block/AnchorBlock.vue";
import TimelineBlock from "@/components/ui/timeline-block/TimelineBlock.vue";
import JobsBlock from "@/components/ui/jobs-block/JobsBlock.vue";

import Vue from 'vue'

export default {
  components: {
    AccordionList,
    'news-section': NewsSection,
    'tagged-news-block': TaggedNewsBlock,
    'calculator-block': CalculatorBlock,
    'cars-block': CarsBlock,
    'media-text-block': MediaTextBlock,

    'text-boxes-block': TextBoxesBlock,
    'two-column-block': TwoColumnBlock,
    'logo-slider-block': LogoSliderBlock,
    'iframe-block': IframeBlock,

    'timeline-block': TimelineBlock,
    'jobs-block': JobsBlock,
    'faq-block': FAQBlock,
    'compare-table-block': CompareTableBlock,
    'car-bubble-block': CarBubbleBlock,
    'soft-conversion-block': SoftConversionBlock,
    'test-area-block': TestAreaBlock,
    'anchor-block': AnchorBlock,

    'HTMLFragment': {
      functional: true,
      props: ['html'],
      render(h, ctx) {
        const nodes = new Vue({
          beforeCreate() {
            this.$createElement = h
          },
          template: `<div>${ctx.props.html}</div>`
        }).$mount()._vnode.children
        return nodes
      }
    }
  },
  props: ['cmsGlobalData', 'cmsPageData'],
  data() {
    return {
      content: null,
      blocks: null,
      title: null,
    }
  },
  beforeMount() {

  },
  created() {
    this.$watch(
        () => this.$props.cmsGlobalData,
        (toParams, previousParams) => {
          this.setCMSGlobalData();
        }
    );
    this.$watch(
        () => this.$props.cmsPageData,
        (toParams, previousParams) => {
          if (this.cmsPageData.page.dynamic_page) {
            this.setCMSPageData();
          }
        }
    );
    this.setCMSGlobalData();
    this.setCMSPageData();
  },
  methods: {
    setCMSGlobalData() {
    },
    setCMSPageData() {

      var content = this.cmsPageData.page.dynamic_page.content;
      var title = this.cmsPageData.page.dynamic_page.title;
      var blocks = this.cmsPageData.page.dynamic_page.blocks;
      var groups = [];

      var groupID = -1;
      var textualContentOpen = false;
      for (var i = 0; i < blocks.length; i++) {
        var block = blocks[i];
        if (block.name == 'acf/accordion') {
          var itemsCount = block.attrs.accordion.value;
          var items = [];
          for (var j = 0; j < itemsCount; j++) {
            items.push({
              "ID": j + 1,
              "title": block.attrs['accordion_' + j + '_title'].value,
              "content": block.attrs['accordion_' + j + '_content'].value,
              "icon": block.attrs['accordion_' + j + '_icon'].value,
            });
          }
          block.accordion = items;
        }
        if (block.name == 'acf/youtube-video') {
          if (block.attrs.video && block.attrs.video.value) {
            block.attrs.video.value = utils.getYoutubeVideoId(block.attrs.video.value);
          }
        }
        blocks[i] = block;

        if (block.name.indexOf("core/") === 0 ||
            block.name === 'acf/accordion' ||
            block.name === 'acf/youtube-video'
        ) {
          if (!textualContentOpen) {
            groupID++;
            groups[groupID] = {"name": "textualContent", "blocks": []};
            textualContentOpen = true;
          }
        } else {
          groupID++;
          groups[groupID] = {"name": block.name, "blocks": []};
          textualContentOpen = false;
        }
        groups[groupID].blocks.push(block);
      }

      this.title = title;
      this.content = content;
      this.blocks = groups;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.dynamic-blocks {
  .acf-block {
    &:first-child {
      ::v-deep section {
        margin-top: 0;
      }
    }
  }

  .acf-iframe-block:first-child {
    ::v-deep .iframe-block {
      margin-top: 0;
    }
  }
}

.top-hero {
  margin-bottom: 80px;
  margin-top: 60px;

  h1 {
    font-weight: 700;
    text-align: center;
  }
}

.hero-section {
  margin-bottom: 100px;

  .top-part {
    background: #E8F5F4;
    border-radius: 40px;
    padding-bottom: 170px;
    text-align: center;
    padding-top: 80px;
    margin: 0 20px;

    &.only-top {
      padding-bottom: 80px;
    }

    .logo {
      height: 25px;
      margin-bottom: 8px;

      img {
        max-height: 100%;
        max-width: auto;
      }
    }

    .wrap {
      max-width: 710px;
      margin-left: auto;
      margin-right: auto;
    }

    .content {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4C6C69;
      margin-top: 40px;

      ::v-deep p {
        margin-top: 1.4rem;
      }
    }
  }

  .contacts {
    margin-top: 40px;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #1F4743;
      text-decoration: none;
      margin-left: 24px;
      margin-right: 24px;
      display: inline-block;

      .icon {
        display: inline-block;
        vertical-align: top;
        margin-right: 12px;
      }

      .label {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .bottom-part {
    text-align: center;
    margin-top: -90px;
  }

  .image {
    overflow: hidden;
    border-radius: 20px;

    img {
      border-radius: 20px;
    }
  }

  .big-image {
    width: 100%;
    border-radius: 20px;
    position: relative;

    img {
      width: 100%;
      border-radius: 20px;
      margin-bottom: -120px;
      box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    }

    video {
      width: 100%;
      border-radius: 20px;
      margin-bottom: 0px;
      box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    }

    .tagline {
      position: absolute;
      top: 0;
      left: 0;
      padding: 16px 32px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
      width: 100%;
      text-align: left;
      box-sizing: border-box;
      padding-left: 32px;
      padding-right: 92px;
      padding-bottom: 36px;
      background: linear-gradient(180deg, rgba(28, 28, 27, 0.882614) 0%, rgba(28, 28, 27, 0.491329) 43.75%, rgba(28, 28, 27, 0) 100%);
      border-radius: 20px 20px 0 0;
      opacity: 0;
      transition: all 300ms;
      -webkit-animation: all 300ms;
    }

    .arrow {
      position: absolute;
      opacity: 0;
      display: block;
      top: 20px;
      right: 32px;
      transform: translateX(-10px);
      -webkit-transform: translateX(-10px);
      transition: all 300ms;
      -webkit-animation: all 300ms;
    }

    .arrow-small {
      display: none;
      transform: translateX(-4px);
      -webkit-transform: translateX(-4px);
      transition: all 300ms;
      -webkit-animation: all 300ms;
    }

    &:hover {
      .tagline {
        opacity: 1;
      }

      .arrow {
        opacity: 1;
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
      }
    }
  }
}

.hero2-section {
  margin-bottom: 100px;

  .video-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .video-container video {
    width: 100%;
    height: 580px;
    display: block;
    object-fit: cover;
    border-radius: 40px;
  }

  .video-container .side {
    position: absolute;
    top: 0;
    left: 0;
    width: 450px;
    height: 580px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 90px 50px 50px 50px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    text-align: left;
  }

  .side {
    max-width: 450px;
    height: 580px;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 90px 50px 50px 50px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    text-align: left;
  }

  .title {
    font-size: 40px;
    line-height: 50px;
  }

  .button {
    margin-top: 40px;
    width: 200px;
    text-align: center;
  }

  .top-part {
    height: 580px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    border-radius: 40px;
    text-align: center;
    margin: 0 20px;
    box-shadow: 0 32px 64px -24px rgba(31, 72, 68, 0.16);

    .wrap {
      max-width: 710px;
      margin-left: auto;
      margin-right: auto;
    }

    .content {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      margin-top: 40px;

      ::v-deep p {
        margin-top: 1.4rem;
        color: #1F4743 !important;
      }
    }
  }

  .bottom-part {
    text-align: center;
    margin-top: -90px;
  }

  .image {
    overflow: hidden;
    border-radius: 20px;

    img {
      border-radius: 20px;
    }
  }

  .big-image {
    width: 100%;
    border-radius: 20px;
    position: relative;

    img {
      width: 100%;
      border-radius: 20px;
      margin-bottom: -120px;
      box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    }

    video {
      width: 100%;
      border-radius: 20px;
      margin-bottom: 0px;
      box-shadow: 0px 32px 64px -24px rgba(31, 72, 68, 0.16);
    }
  }
}

.related-green-section {
  padding-top: 96px;
  padding-bottom: 140px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: $color-super-light-green;
  border-radius: 40px;
}

@media (max-width: #{$screen-tablet}) {
  .related-green-section {
    padding-bottom: 60px;
    padding-top: 48px;
  }
  .hero-section {
    .big-image {
      .tagline-wrap {
        position: relative;
      }

      .arrow-small {
        display: block;
        opacity: 1;
        position: absolute;
        right: 0px;
        top: 50%;
        margin-top: -6px;
      }

      .tagline {
        background: transparent;
        border-radius: 0px;
        position: relative;
        opacity: 1;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 133%;
        letter-spacing: -0.01em;
        color: #4C6C69;
        top: auto;
        left: auto;
        display: block;
        padding: 12px 16px;
        padding-right: 60px;
        text-align: left;

      }

      .arrow {
        display: none;
      }

      &:hover {
        .arrow-small {
          opacity: 1;
          transform: translateX(0px);
          -webkit-transform: translateX(0px);
        }
      }
    }
  }
}

@media (max-width: #{$screen-mobile}) {

  .related-green-section {
    padding-bottom: 60px;
    padding-top: 48px;
    margin-left: 0;
    margin-right: 0;
  }

  .hero-section {
    margin-bottom: 32px;

    .top-part {
      padding-top: 40px;
      padding-bottom: 130px;
      padding-bottom: 40px;

      &.with-video {
        padding-bottom: 130px;
      }

      &.with-image {
        padding-bottom: 130px;
      }

      .content {
        margin-top: 10px;
        font-size: 16px;
        line-height: 130%;
      }
    }
  }

  .hero2-section {
    .side {
      border-radius: 40px;
    }
  }

}
</style>