<template>
  <div id="app">
      <mainview />
  </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import Mainview from '@/components/shared/mainview';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import UserData, { StorageType } from '@/components/leasing/Showroom/src/components/providers/UserData';
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    (el as any).clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', (el as any).clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', (el as any).clickOutsideEvent)
  },
});


@Component({
  components: {
    Mainview
  },
})
export default class App extends Vue {
  created(){

    // OPENDO-3513 make sure user is logged out on new re-visit on frontpage
    if (window.location.pathname == '/'){
      UserData.logout()
    }

    changedRoute();

    function getAPIURL() {
      if (window.location.hostname === "www-stage.opendo.dk") {
        return "https://www-stage.opendo.dk/wp-api";
      } else if (window.location.hostname === "www-test.opendo.dk" || window.location.hostname === "localhost") {
        return "https://www-test.opendo.dk/wp-api";
      } else {
        return "https://www.opendo.dk/wp-api";
      }
    }


    function changedRoute() {
      var host = getAPIURL();
      var src = "";
      var script = document.getElementById('pagecmsscript');
      if (script){
        src = script.getAttribute("src");
      }
      var query = btoa(window.location.search);
      // SEMLER-197: erhverv should be used as the frontpage
      const path = window.location.pathname === '/' ? '/erhverv' : window.location.pathname;
      var newSrc = host + "/?cmsdata&path="+path+(query ? '&query='+query : '');

      if (src!=newSrc) {
        document.getElementById('pagecmsscript').remove();
        script = document.createElement("script");
        script.setAttribute("src", newSrc);
        script.setAttribute("id", "pagecmsscript");
        document.getElementById('cmsloader').after(script);
      }
    }
    //Remote script loading after path change (CMS DATA) Check also private/index.html
    this.$watch(
      () => this.$route.name,
      (toParams, previousParams) => {
        changedRoute();
      }
    );
    this.$watch(
      () => this.$route.query,
      (toParams, previousParams) => {
        changedRoute();
      }
    )
  }
}
</script>

<style lang="scss">
  @import "~@/styles/reset.scss";
  @import "~@/style.scss";
</style>
