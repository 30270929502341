import { Component, Vue } from "vue-property-decorator";

import dynamicPage from "../dynamic_page/DynamicPage.vue"
import newsSingle from "../news/news_single"
import newsSingleDynamic from "../news/news_single_dynamic"
import subpage from "../subpage/Subpage.vue"
import template404 from "../NotFound/NotFound"
import templateRouter from "./CustomRouteHandler.vue"

@Component({
    mixins:[templateRouter],
    props: ['cmsGlobalData', 'cmsPageData'],
    components: {
        'not-found' : template404,
        'subpage' : subpage,
        'dynamicPage' : dynamicPage,
        'newsSingle' : newsSingle,
        'newsSingleDynamic' : newsSingleDynamic,
    }
})
export default class CustomRouteHandler extends Vue {
    page: string = null;
    created() {
        this.$watch(
            () => this.$props.cmsPageData,
            (toParams, previousParams) => {
                if (this.$props.cmsPageData.page.subpage){
                    this.page = "subpage";
                }else if (this.$props.cmsPageData.page.news_article){
                    if (this.$props.cmsPageData.page.news_article.templates && this.$props.cmsPageData.page.news_article.templates.length>0 && this.$props.cmsPageData.page.news_article.templates[0].name == "Car comparison"){
                        this.page = "news_article_dynamic";
                    }else{
                        this.page = "news_article";
                    }
                }else if (this.$props.cmsPageData.page.dynamic_page){
                    this.page = "dynamic_page";
                }else if (this.$props.cmsPageData.page.homepage) {
                  // Don't show 404 on homepage
                } else {
                    this.page = "404";
                }
            }
        );
    }
}