import Axios from 'axios';
import Toggle from '@/components/leasing/Showroom/src/common/ab_toggle';

/**
 * axios wrapper https://github.com/axios/axios
 */
class AbAxiosLeasingReporting {
    private axios = Axios.create({
        timeout: 60000,
    });
    public abtoggle = new Toggle();

    constructor() {
        if (window.location.hostname === 'www-stage.opendo.dk') {
            this.axios.defaults.baseURL = 'https://api-stage.opendo.dk';
        } else if (window.location.hostname === 'www-test.opendo.dk' || window.location.hostname === "localhost") {
            this.axios.defaults.baseURL = 'https://api-test.opendo.dk';
        } else {
            this.axios.defaults.baseURL = 'https://api.opendo.dk';
        }
    }

    /**
     *
     * @param data
     * @param successCallback
     * @param errorFallback
     */
    public async sendFormMail(data, successCallback, errorFallback) {
        this.abtoggle.modal({ overlay: true, spinner: true });

        const path= '/leasing-reporting/contactform';

        this.axios.post(path, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
          .then((response) => {
              successCallback(response);
          })
          .catch(function (thrown) {
              errorFallback(thrown);
          });
    }
}

export default AbAxiosLeasingReporting;
